<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Popover class="relative bg-black">
    <!-- MENU -->
    <div class="max-w-7xl mx-auto px-4 sm:px-6">
      <div
        class="flex justify-between items-center py-6 md:justify-start md:space-x-10"
      >
        <div class="flex justify-start lg:flex-1">
          <router-link to="/">
            <img class="h-8 w-auto sm:h-10" src="@/assets/DDlogo.png" alt="" />
            <!-- <h1 class="text-3xl font font-extrabold text-G sm:text-3xl">
              Direct Debs.
            </h1> -->
          </router-link>
        </div>
        <div class="-mr-2 -my-2 md:hidden">
          <PopoverButton
            class="bg-black rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          >
            <span class="sr-only">Open menu</span>
            <MenuIcon class="h-6 w-6" aria-hidden="true" />
          </PopoverButton>
        </div>
        <PopoverGroup as="nav" class="hidden md:flex space-x-10">
          <router-link
            to="/"
            class="text-base font-medium text-white hover:text-G"
            active-class="text-G border-b-2 border-white "
          >
            Home
          </router-link>
          <router-link
            to="/debs"
            class="text-base font-medium text-white hover:text-G"
            active-class="text-G border-b-2 border-white "
          >
            Debs
          </router-link>
          <router-link
            to="/predebs"
            class="text-base font-medium text-white hover:text-G"
            active-class="text-G border-b-2 border-white "
          >
            Pre-Debs
          </router-link>
          <router-link
            to="/graduations"
            class="text-base font-medium text-white hover:text-G"
            active-class="text-G border-b-2 border-white "
          >
            Graduations
          </router-link>
          <router-link
            to="/contact"
            class="text-base font-medium text-white hover:text-G"
            active-class="text-G border-b-2 border-white "
          >
            Contact & FAQ's
          </router-link>
        </PopoverGroup>
        <div class="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          <router-link
            to="/contact"
            class="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-G hover:bg-yellow-700"
          >
            Inquiry Form
          </router-link>
        </div>
      </div>
    </div>
    <!-- MOBILE MENU -->
    <transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <PopoverPanel
        focus
        class="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50"
      >
        <div
          class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-black divide-y-2 divide-gray-50"
        >
          <div class="pt-5 pb-6 px-5">
            <div class="flex items-center justify-between">
              <div>
                <img class="h-6 w-auto" src="@/assets/DDlogo.png" alt="" />
              </div>
              <div class="-mr-2">
                <PopoverButton
                  class="bg-black rounded-md p-2 inline-flex items-center justify-center text-yellow-200 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                >
                  <span class="sr-only">Close menu</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
            <div class="mt-6">
              <nav class="grid gap-y-8">
                <router-link
                  v-for="item in solutions"
                  :key="item.name"
                  :to="item.to"
                  class="-m-3 p-3 flex items-center hover:bg-G"
                  active-class="border-b-2 border-white"
                >
                  <component
                    :is="item.icon"
                    class="flex-shrink-0 h-6 w-6"
                    aria-hidden="true"
                  />
                  <span
                    class="ml-3 text-base font-medium text-white"
                    active-class="text-G border-b-2 border-white"
                  >
                    {{ item.name }}
                  </span>
                </router-link>
              </nav>
            </div>
          </div>
          <div class="py-6 px-5 space-y-6">
            <div>
              <router-link
                to="/contact"
                class="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-G hover:bg-yellow-800"
              >
                Sign up
              </router-link>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script setup>
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/vue";
import {
  BookmarkAltIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorClickIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  XIcon,
  AcademicCap,
} from "@heroicons/vue/outline";
import { ChevronDownIcon } from "@heroicons/vue/solid";

const solutions = [
  {
    name: "Home",
    to: "/",
  },
  {
    name: "Debs",
    to: "/debs",
  },
  {
    name: "Pre-Debs",
    to: "/predebs",
  },
  {
    name: "Graduations ",
    to: "/graduations",
  },
  {
    name: "Contact & FAQ's ",
    to: "/contacts",
  },
];
</script>
