import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "Home | Direct Debs",
      dynamicParam: "-",
      metaTags: [
        {
          name: "description",
          content:
            "Direct Debs is Irelands number 1 event organiser for Debs, Pre-Debs & Grads, Book now by contacting us at bookings@directdebs.com!",
        },
        {
          property: "og:description",
          content:
            "Direct Debs is Irelands number 1 event organiser for Debs, Pre-Debs & Grads, Book now by contacting us at bookings@directdebs.com!",
        },
      ],
    },
    component: () => import("../views/Home.vue"),
  },
  //
  //
  {
    path: "/debs",
    name: "Debs",
    meta: {
      title: "Debs | Direct Debs",
      dynamicParam: "-",
      metaTags: [
        {
          name: "description",
          content:
            "The grand extravaganza of 6th and the right of passage from secondary school to college. This is the night you have been waiting for and involves months of planning and discussion and one last night out with the members of your year who you have spent years of friendship with in school. Choose from some of the best hotels in Ireland. At Direct Debs we can cater for groups of all sizes from 60 to 300+ with packages starting from €75 to cater for all your requirements and everything to make your dream Debs a reality. We can accommodate requests of all sorts and will plan every last moment of your Debs down to the finest details. Direct Debs works with over 30+ exquisite Debs venues across the country each one perfectly suited to make your special day filled with memorable occasion that will last a lifetime. For further information on our Debs venues and packages please complete our booking form or contact us at bookings@directdebs.ie for a free quote, a complimentary brochure and a full list of all our venues.",
        },
        {
          property: "og:description",
          content:
            "The grand extravaganza of 6th and the right of passage from secondary school to college. This is the night you have been waiting for and involves months of planning and discussion and one last night out with the members of your year who you have spent years of friendship with in school. Choose from some of the best hotels in Ireland. At Direct Debs we can cater for groups of all sizes from 60 to 300+ with packages starting from €75 to cater for all your requirements and everything to make your dream Debs a reality. We can accommodate requests of all sorts and will plan every last moment of your Debs down to the finest details. Direct Debs works with over 30+ exquisite Debs venues across the country each one perfectly suited to make your special day filled with memorable occasion that will last a lifetime. For further information on our Debs venues and packages please complete our booking form or contact us at bookings@directdebs.ie for a free quote, a complimentary brochure and a full list of all our venues.",
        },
      ],
    },
    component: () => import("../views/Debs.vue"),
  },
  //
  //
  {
    path: "/predebs",
    name: "Pre-Debs",
    meta: {
      title: "Pre-Debs | Direct Debs",
      dynamicParam: "-",
      metaTags: [
        {
          name: "description",
          content:
            "Can’t wait for the Debs itself, why not host a pre-debs to create some great memories and create a some excitement of the debs itself. The Pre-Debs is a memorable night that most students will always remember. A Pre Debs is a not just casual night out, it’s one of the highlights of 6th year that everyone in the year gets dressed up and plan a night out together in a nightclub of their choosing and dance late into the night. It is a night made with memories, laughter and a bit of craic. Not to mention it is a key date in the calendar of every 6th year student and is something to look forward to after weeks of study and exams. It is no surprise that Direct Debs host some of the best Pre-Debs in the country as we are partnered with some of Ireland’s best nightclubs, all within 1 hour from your location. So that means more partying and less time travelling. We can cater for groups of all sizes, and our events team will be sure to provide you with a night full of memories that will last a lifetime. At all of our events our production and events team are on site to ensure every part of Pre-Debs runs as planned and to ensure that your event is full of confetti, Balloon drops and sparklers to give it the wow factor. For further information on our Pre-Debs venues and packages please complete our booking form or contact us at bookings@directdebs.ie for a free quote, a complimentary brochure and a full list of all our venues.",
        },
        {
          property: "og:description",
          content:
            "Can’t wait for the Debs itself, why not host a pre-debs to create some great memories and create a some excitement of the debs itself. The Pre-Debs is a memorable night that most students will always remember. A Pre Debs is a not just casual night out, it’s one of the highlights of 6th year that everyone in the year gets dressed up and plan a night out together in a nightclub of their choosing and dance late into the night. It is a night made with memories, laughter and a bit of craic. Not to mention it is a key date in the calendar of every 6th year student and is something to look forward to after weeks of study and exams. It is no surprise that Direct Debs host some of the best Pre-Debs in the country as we are partnered with some of Ireland’s best nightclubs, all within 1 hour from your location. So that means more partying and less time travelling. We can cater for groups of all sizes, and our events team will be sure to provide you with a night full of memories that will last a lifetime. At all of our events our production and events team are on site to ensure every part of Pre-Debs runs as planned and to ensure that your event is full of confetti, Balloon drops and sparklers to give it the wow factor. For further information on our Pre-Debs venues and packages please complete our booking form or contact us at bookings@directdebs.ie for a free quote, a complimentary brochure and a full list of all our venues.",
        },
      ],
    },
    component: () => import("../views/PreDebs.vue"),
  },
  //
  //
  {
    path: "/graduations",
    name: "Graduations",
    meta: {
      title: "Graduations | Direct Debs",
      dynamicParam: "-",
      metaTags: [
        {
          name: "description",
          content:
            "The Graduation night is one of the very last nights out in 6th year with everyone from your year. After the Graduation ceremony which is hosted by your school everyone heads out for a night out to celebrate finishing school with one last night out to commemorate the end of 6 long years of school together with all your class mates and the members of your year. This takes place in a nightclub which goes late into the night and creates so many memories for students filled with laughter, dancing and looking back over the years together as a group. Direct Debs is partnered with some of Ireland’s most popular clubs and is known to host the biggest Graduation parties with 5,000 students in attendance across multiple venues. Book your school in today, simply by dropping us a message. For further information on our Graduation venues and packages please complete our booking form or contact us at bookings@directdebs.ie for a free quote, a complimentary brochure and a full list of all our venues",
        },
        {
          property: "og:description",
          content:
            "The Graduation night is one of the very last nights out in 6th year with everyone from your year. After the Graduation ceremony which is hosted by your school everyone heads out for a night out to celebrate finishing school with one last night out to commemorate the end of 6 long years of school together with all your class mates and the members of your year. This takes place in a nightclub which goes late into the night and creates so many memories for students filled with laughter, dancing and looking back over the years together as a group. Direct Debs is partnered with some of Ireland’s most popular clubs and is known to host the biggest Graduation parties with 5,000 students in attendance across multiple venues. Book your school in today, simply by dropping us a message. For further information on our Graduation venues and packages please complete our booking form or contact us at bookings@directdebs.ie for a free quote, a complimentary brochure and a full list of all our venues",
        },
      ],
    },
    component: () => import("../views/Grads.vue"),
  },
  //
  //
  {
    path: "/contact",
    name: "contact",
    meta: {
      title: "Contact & FAQ's | Direct Debs",
      dynamicParam: "-",
      metaTags: [
        {
          name: "description",
          content:
            "Contact Direct Debs now via email ( bookings@directdebs.ie ) or fill out our contact form! Direct Debs offers the number 1 Debs, Pre-Debs & Graduation experience in Ireland. With thousands of happy customers across the country, Direct debs is the only choice for your special night!",
        },
        {
          property: "og:description",
          content:
            "Contact Direct Debs now via email ( bookings@directdebs.ie ) or fill out our contact form! Direct Debs offers the number 1 Debs, Pre-Debs & Graduation experience in Ireland. With thousands of happy customers across the country, Direct debs is the only choice for your special night!",
        },
      ],
    },
    component: () => import("../views/Contact.vue"),
  },
  //
  {
    path: "/admin",
    name: "Admin",
    meta: {
      title: "Admin | Direct Debs",
      dynamicParam: "-",
      metaTags: [
        {
          name: "description",
          content: "Direct Debs Staff",
        },
        {
          property: "og:description",
          content: "Direct Debs Staff",
        },
      ],
    },
    component: () => import("../views/Admin.vue"),
  },
  //
  {
    path: "/:pathMatch(.*)*",
    name: "Page Not Found",
    meta: {
      title: "Page Not Found | Three Storey",
      dynamicParam: "-",
    },
    component: () => import("../views/NotFound.vue"),
  },
];

const scrollBehavior = (to, from, savedPosition) => {
  if (to.hash) {
    setTimeout(() => {
      const element = document.getElementById(to.hash.replace(/#/, ""));
      if (element && element.scrollIntoView) {
        element.scrollIntoView({
          block: "end",
          behavior: "smooth",
        });
      }
    }, 500);
    return {
      el: to.hash,
    };
  } else if (savedPosition) {
    return savedPosition;
  }
  return {
    top: 0,
  };
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

router.beforeEach((to, from, next) => {
  // window.console.log(to.path)
  // if (localStorage.getItem('roleID') === null && to.path != '/login') {
  //   window.location.href = '/login'
  // }

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title && r.meta.dynamicParam);
  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    if (
      nearestWithTitle.meta.dynamicParam === undefined ||
      nearestWithTitle.meta.dynamicParam === "-"
    ) {
      document.title = nearestWithTitle.meta.title;
    } else {
      const pageTitle = nearestWithTitle.meta.title.replace(
        ":" + nearestWithTitle.meta.dynamicParam,
        to.params[nearestWithTitle.meta.dynamicParam]
          .replace(/[^a-zA-Z0-9]/g, " ")
          .replace(/\b[a-z]/g, (x) => x.toUpperCase())
      );
      document.title = pageTitle;
    }
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));
  next();
});

export default router;
